import React from "react";
import { Box, BoxContainer, BoxSide1, BoxSide2, BoxSide3, BoxSide4, BoxSide5, BoxSide6 } from "./Box1Styles";

const Box1 = () => {
  return (
    <BoxContainer>
      <Box>
        <BoxSide1></BoxSide1>
        <BoxSide2></BoxSide2>
        <BoxSide3></BoxSide3>
        <BoxSide4></BoxSide4>
        <BoxSide5></BoxSide5>
        <BoxSide6></BoxSide6>
      </Box>

    </BoxContainer>
  )
}

export default Box1;