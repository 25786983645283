import styled from "styled-components";

export const BoxContainer = styled.div`
width: 200px;
height: 200px;
perspective: 500px;
padding:5rem;
`
export const Box = styled.div`
width: 200px;
height: 200px;
transform-style: preserve-3d;
transform: rotate3d(1, 1, 0, 45deg);
animation: turn2 20s linear infinite;
@keyframes turn2 {
  from { transform: rotate3d(0, 0, 0, 0); }
  to { transform: rotate3d(0, 1, 0, 360deg); }
}

`
export const BoxSide = styled.div`
width: 200px;
height: 200px;
border: 2px solid black;
position: absolute;
opacity: 1;
display: flex;
align-items: center;
justify-content: center;
font-size: 2rem;
border-color:#18E818;
`
export const BoxSide1 = styled(BoxSide)`
transform: translateZ(100px);
`
export const BoxSide2 = styled(BoxSide)`
transform: translateZ(-100px) rotateY(180deg);
`
export const BoxSide3 = styled(BoxSide)`
transform: translateX(-100px) rotateY(-90deg);
`
export const BoxSide4 = styled(BoxSide)`
transform: translateX(100px) rotateY(90deg);
`
export const BoxSide5 = styled(BoxSide)`
transform: translateY(-100px) rotateX(90deg);
`
export const BoxSide6 = styled(BoxSide)`
transform: translateY(100px) rotateX(-90deg);
`