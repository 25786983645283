import React, { useEffect, useState } from "react";
import {
  AboutContainer,
  AboutDescription,
  AboutTitle,
  BodyContainer,
  DownloadLink,
  FooterContainer,
  FooterDescription,
  HeroContainer,
  HeroTitle,
  HomePageContainer,
  PageExitTransition,
  ProjectsTitle,
  SocialsContainer,
  SocialsItem,
  Sun,
  WebsiteCodeUsed,
  WebsiteContainer,
  WebsiteDescription,
  WebsiteTitle,
} from "./homePageStyles";
import {
  BiLogoLinkedinSquare,
  BiLogoGithub,
  BiLogoYoutube,
} from "react-icons/bi";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayerIndex";
import taiwanBlogDemo from "../../assets/taiwan-blog-demo.mp4";
import taiwanFamilyDemo from "../../assets/taiwan-family-demo.mp4";
import inplaceDemo from "../../assets/inplace-demo.mp4";
import unloadDemo from "../../assets/unload-demo.mp4";
import Box1 from "../../components/Box1/Box1";
import Box2 from "../../components/Box2/Box2";
import { HiArrowRight } from "react-icons/hi"; // Example: HiArrowRight from the "Heroicons" pack
import resumeFile from "../../assets/marcel-yap-resume.pdf";
import popclickerdemo from "../../assets/popclicker-demo.mp4";
import geometriesdemo from "../../assets/geometries-demo.mp4";
import memphisdesign from "../../assets/memphisdesign-demo.mp4";
import cardesigndemo from "../../assets/cardesign-demo.mp4";
import mrtdoordesignanalysisdemo from "../../assets/mrtdoordesignanalysis-demo.mp4";

const HomePage = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener to track window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const websiteData = [
    {
      title: "Taiwan Blog",
      description:
        "A blog of my adventures in taiwan during my 6 months internship there",
      codeUsed: "React, GraphQL, 2023",
      link: "https://marcel-taiwan-adventures.netlify.app",
      video: taiwanBlogDemo,
    },
    {
      title: "Design Analysis: MRT Train Door Screen",
      description:
        "Design Module: A visual essay for the critique of the MRT train door screen's user interface and recommendations for improvement",
      codeUsed: "React, 2023",
      link: "https://design-analysis-mrt-train-screen.netlify.app/",
      video: mrtdoordesignanalysisdemo,
    },

    {
      title: "Design in Industry Sectors Report: Car Design",
      description:
        "Design Module: A visual essay for design process and how this leads to the application of design thinking in the car industry",
      codeUsed: "React, 2023",
      link: "https://memphis-design.netlify.app/",
      video: cardesigndemo,
    },
    {
      title: "Design History: Memphis Design Movement",
      description:
        "Design Module: A visual essay for the research of the Memphis Design Movement ",
      codeUsed: "React, 2023",
      link: "https://memphis-design.netlify.app/",
      video: memphisdesign,
    },
    {
      title: "Geometries Playground",
      description:
        "A practice project while learning how to use three.js geometries",
      codeUsed: "React, Three.js, 2023",
      link: "https://geometries.netlify.app/",
      video: geometriesdemo,
    },
    {
      title: "Pop Clicker ",
      description:
        "A mini clicker game created while learning how to use tailwind css, made in 1 day",
      codeUsed: "React, Tailwind CSS,  2023",
      link: "https://pop-clicker.netlify.app/",
      video: popclickerdemo,
    },
    {
      title: "IdeaBox, A place for ideas (In Progress)",
      description:
        "A practice project to learn about firebase and practising proper design process",
      codeUsed: "React, Firebase, 2023",
      link: "https://ideasdump.netlify.app/",
      video: unloadDemo,
    },
    {
      title: "Family Trip to Taiwan",
      description: "A website to plan where to go in Taiwan for a family trip",
      codeUsed: "React, 2023",
      link: "https://yapfamilytaiwantrip.netlify.app",
      video: taiwanFamilyDemo,
    },
    {
      title: "Revamp of UI of University Intern Portal",
      description:
        "Part of a school module to improve the user experience of the portal based on user research and testing",
      codeUsed: "HTML, CSS, 2022",
      link: "https://inplace-revamp.netlify.app/inplace.html",
      video: inplaceDemo,
    },

    //https://ideasdump.netlify.app/
  ];

  const theme = props.theme;
  const [clickedIndex, setClickedIndex] = useState(-1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleLinkClick = (link, index) => {
    // setClickedIndex(index)
    setTimeout(() => {
      window.open(link, "_blank");
      // setClickedIndex(-1)
    }, 300);
  };

  return (
    <HomePageContainer clicked={clickedIndex}>
      {/* <PageExitTransition clicked={clickedIndex} /> */}
      {/* <Sun /> */}
      <Box2
        style={{
          position: "absolute",
          top: windowWidth <= 650 ? "10rem" : "0",
          transform: "scale(0.7)",
        }}
      />
      <HeroContainer>
        <HeroTitle>Marcel's Portfolio</HeroTitle>
      </HeroContainer>
      <BodyContainer theme={theme}>
        <ProjectsTitle>Projects</ProjectsTitle>
        {websiteData.map((website, index) => {
          return (
            <WebsiteContainer
              clicked={clickedIndex}
              index={index}
              key={index}
              onClick={() => handleLinkClick(website.link, index)}
            >
              <WebsiteTitle>{website.title}</WebsiteTitle>
              <WebsiteDescription>{website.description}</WebsiteDescription>

              <VideoPlayer src={website.video} />
              <WebsiteCodeUsed>{website.codeUsed}</WebsiteCodeUsed>
              {/* <HiArrowRight size={"1rem"} /> */}
            </WebsiteContainer>
          );
        })}
      </BodyContainer>
      <AboutContainer>
        <AboutTitle>About</AboutTitle>
        <AboutDescription>
          Year 4 Information Engineering and Media
        </AboutDescription>
        <AboutDescription>Nanyang Technological University</AboutDescription>
        <AboutDescription>
          Just finished a 6-month internship in Taiwan
        </AboutDescription>
      </AboutContainer>

      <FooterContainer>
        <FooterDescription>
          Marcelyap31@gmail.com
          <br />
          +65 97774671
          <DownloadLink href={resumeFile} download>
            Resume
          </DownloadLink>
        </FooterDescription>
        <SocialsContainer>
          <SocialsItem
            hoverColor="#0077B7"
            href="https://www.linkedin.com/in/marcel-yapp/"
            target="_blank"
          >
            <BiLogoLinkedinSquare size={35} />
          </SocialsItem>
          <SocialsItem
            hoverColor="#800080"
            href="https://github.com/GnawedLeaves"
            target="_blank"
          >
            <BiLogoGithub size={35} />
          </SocialsItem>
          <SocialsItem
            hoverColor="#FF0000"
            href="https://www.youtube.com/channel/UCwUZ63xnEzkfvLA2JtKhdtQ"
            target="_blank"
          >
            <BiLogoYoutube size={35} />
          </SocialsItem>
        </SocialsContainer>
      </FooterContainer>
    </HomePageContainer>
  );
};

export default HomePage;
