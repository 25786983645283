import styled from "styled-components";

export const HomePageContainer = styled.div`
  background: #00091b;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: ${(props) => (props.clicked !== -1 ? "translateX(-100%)" : "")};
  transition: 0.5s;
  cursor: default;
`;

export const HeroContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 650px) {
    height: 45rem;
  }
`;

export const HeroTitle = styled.div`
  font-size: 5vw;
  text-align: center;

  // letter-spacing:0.5rem;
  @media only screen and (max-width: 650px) {
    font-size: 2.5rem;
  }
`;
export const CircleTransitionBackground = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  mix-blend-mode: exclusion;
  //mix-blend-mode: screen;
  pointer-events: none;
`;

export const CircleTransitionDiv = styled.div`
  width: 20vw;
  height: 20vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: black;
  border: none;
  border-color: none;
  border-radius: 50%;

  ${(props) =>
    props.theme === "dark"
      ? "animation: 0.5s CircleTransition ease-in-out forwards"
      : "animation: 0.5s CircleTransition ease-in-out forwards reverse"};

  @media only screen and (max-width: 650px) {
    ${(props) =>
      props.theme === "dark"
        ? "animation: 0.5s CircleTransitionMobile ease-in-out forwards"
        : "animation: 0.5s CircleTransitionMobile ease-in-out forwards reverse"};
  }

  @keyframes CircleTransition {
    0% {
      width: 0vw;
      height: 0vw;
    }
    100% {
      width: 200vw;
      height: 200vw;
      pointer-events: none;
    }
  }

  @keyframes CircleTransitionMobile {
    0% {
      width: 0vw;
      height: 0vw;
    }
    100% {
      width: 100rem;
      height: 100rem;
      pointer-events: none;
    }
  }
`;

export const Sun = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10rem;
  height: 10rem;
  background: #e73213;
  border-radius: 50%;
  animation: 1.3s Rise ease-in-out forwards;
  @keyframes Rise {
    0% {
      opacity: 0;
      top: 100%;
    }
    100% {
      opacity: 1;
      top: 20%;
    }
  }
  @keyframes RiseMobile {
    0% {
      opacity: 0;
      top: 100%;
    }
    100% {
      opacity: 1;
      top: 10rem;
    }
  }

  @media only screen and (max-width: 650px) {
    width: 10rem;
    height: 10rem;
    top: 10rem;
    animation: 1.3s RiseMobile ease-in-out forwards;
  }
`;
export const ThemeButton = styled.button`
  padding: 0.5rem 0.6rem;
  border: none;
  border-radius: 50%;
  border: 2px solid
    ${(props) => (props.theme === "dark" ? "#FFF6E4" : "#00091B")};
  color: ${(props) => (props.theme === "dark" ? "#FFF6E4" : "#00091B")};
  background: ${(props) => (props.theme === "dark" ? "#00091B" : "#FFF6E4")};
  cursor: pointer;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  transition: all 0.5s ease-in-out;
  z-index: 9999;
  @media only screen and (max-width: 650px) {
    height: fit-content;
    top: 36rem;
    right: 1.5rem;
  }
`;

export const ProjectsTitle = styled.div`
  font-size: 3.5rem;
  letter-spacing: 1rem;
  margin-bottom: 3rem;
  @media only screen and (max-width: 650px) {
    font-size: 2rem;
    letter-spacing: 0.5rem;
  }
`;

export const BodyContainer = styled.div`
  width: 100%;
  mix-blend-mode: revert-layer;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem 0rem;
`;

export const WebsiteContainer = styled.div`
width:45%;
padding:1.5rem 2.5rem;
border:1px solid #FFF6E4;
margin:2rem 0rem;
transition: all 0.3s ease-in-out;
cursor:pointer;

    // display: flex;
    // flex-direction: column;
    // align-items: center;
    // justify-content: center;
}

&:hover{
  background: #FFF6E4;
  color:  #00091B;

}
${(props) =>
  props.index === props.clicked ? "animation: 1.5s blinking 0s ease" : ""};



@keyframes blinking {
  0% {
    background: #FFF6E4;
    color:  #00091B;
  }
  20%{
    background: #00091B;
    color:  #FFF6E4;
  }
  60%{
    background: #FFF6E4;
    color:  #00091B;
  }
  80%{
    background: #00091B;
    color:  #FFF6E4;
  }
}

@media only screen and (max-width: 650px){
  padding:1rem 1rem;
  width:70%;
}
`;
export const WebsiteTitle = styled.div`
  font-size: 2.5rem;
  font-weight: bold;
  width: 100%;
  color: #18e818;
  padding-bottom: 1rem;
  @media only screen and (max-width: 650px) {
    font-size: 1.4rem;
  }
`;
export const WebsiteDescription = styled.div`
  font-size: 1.2rem;

  @media only screen and (max-width: 650px) {
    font-size: 0.8rem;
  }
`;
export const WebsiteCodeUsed = styled.div`
  font-size: 0.8rem;
  @media only screen and (max-width: 650px) {
    font-size: 0.5rem;
  }
`;
export const WebsiteVideoContainer = styled.div`
  height: 10rem;
  border: 1px solid #fff6e4;
`;
export const WebsiteLink = styled.div``;

export const AboutContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 5rem 5rem;
  height: 100vh;
  @media only screen and (max-width: 650px) {
    padding-top: 0;
    height: 30rem;
  }
`;

export const AboutTitle = styled(ProjectsTitle)`
  @media only screen and (max-width: 650px) {
    margin-top: 0;
  }
`;

export const AboutDescription = styled.div`
  font-size: 1.3rem;
  margin: 0.3rem 0;
  text-align: center;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 650px) {
    font-size: 0.8rem;
    width: 80%;
  }
`;

export const FooterContainer = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`;
export const FooterDescription = styled.div`
  font-size: 1rem;
  margin: 0.3rem 0;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const SocialsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
`;
export const SocialsItem = styled.a`
  margin: 0 0.5rem;
  transition: 0.3s;
  text-decoration: none;
  color: #f5f5f5;
  &:hover {
    transform: scale(1.2);
    cursor: pointer;
    color: ${(props) => props.hoverColor};
  }
`;

export const PageExitTransition = styled.div`
  width: 0vw;
  height: 0vw;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #00091b;
  background: #fff6e4;
  border: none;
  border-color: none;
  border-radius: 50%;
  z-index: 9;

  ${(props) =>
    props.clicked !== -1
      ? "animation: 0.5s CircleTransition 0s ease-in-out forwards"
      : ""};

  @keyframes CircleTransition {
    0% {
      width: 0vw;
      height: 0vw;
    }
    100% {
      width: 200vw;
      height: 200vw;
      pointer-events: none;
    }
  }

  @keyframes CircleTransitionMobile {
    0% {
      width: 0vw;
      height: 0vw;
    }
    100% {
      width: 100rem;
      height: 100rem;
      pointer-events: none;
    }
  }

  @media only screen and (max-width: 0px) {
    ${(props) =>
      props.theme === "dark"
        ? "animation: 0.5s CircleTransitionMobile ease-in-out forwards"
        : "animation: 0.5s CircleTransitionMobile ease-in-out forwards reverse"};
  }
`;
export const DownloadLink = styled.a`
  color: #f5f5f5;
`;
