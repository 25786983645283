
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import HomePage from './pages/Home/homePage';
import DumpPage from './pages/Dump/DumpPage';
import { CircleTransitionBackground, CircleTransitionDiv, ThemeButton } from './Appstyles';
import { HiOutlineMoon, HiOutlineSun } from "react-icons/hi";
import DesignPage from './pages/Design/DesignPageIndex';

function App() {

  const [theme, setTheme] = useState("dark");

  const handleThemeChange = () => {
    setTheme(theme === "light" ? "dark" : "light");
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <BrowserRouter>
      <CircleTransitionBackground>
        <CircleTransitionDiv theme={theme} key={theme} />
      </CircleTransitionBackground>
      <ThemeButton theme={theme} onClick={handleThemeChange}>
        {theme === "dark" ? <HiOutlineMoon size={"1.2rem"} /> : <HiOutlineSun size={"1.2rem"} />}
      </ThemeButton>
      <Routes>
        <Route path="/*" element={<HomePage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/dump" element={<DumpPage />} />
        <Route path="/designs" element={<DesignPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
