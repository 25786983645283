import styled from "styled-components";

export const CircleTransitionBackground = styled.div`
width:100%;
height:100vh;
position:fixed;
top:0;
left:0;
background:white;
mix-blend-mode: exclusion;
//mix-blend-mode: screen;
pointer-events:none;
`

export const CircleTransitionDiv = styled.div`
width:20vw;
height:20vw;
position:fixed;
top:50%;
left:50%;
transform: translate(-50%, -50%);
background:black;
border:none;
border-color:none;
border-radius:50%;


${props =>
    props.theme === "dark"
      ? "animation: 0.5s CircleTransition ease-in-out forwards"
      : "animation: 0.5s CircleTransition ease-in-out forwards reverse"};

@media only screen and (max-width: 650px){
  ${props =>
    props.theme === "dark"
      ? "animation: 0.5s CircleTransitionMobile ease-in-out forwards"
      : "animation: 0.5s CircleTransitionMobile ease-in-out forwards reverse"};
}

@keyframes CircleTransition {
  0% {
    width:0vw;
    height:0vw;
  }
  100%{
    width:300vw;
    height:300vw;
    pointer-events:none;
  }
}

@keyframes CircleTransitionMobile {
  0% {
    width:0vw;
    height:0vw;
  }
  100%{
    width:100rem;
    height:100rem;
    pointer-events:none;
  }
}
`
export const ThemeButton = styled.button`
padding:0.5rem 0.6rem;
border:none;
border-radius:50%;
border: 2px solid ${props => props.theme === "dark" ? "#FFF6E4" : "#00091B"};
color: ${props => props.theme === "dark" ? "#FFF6E4" : "#00091B"};
background: ${props => props.theme === "dark" ? "#00091B" : "#FFF6E4"};
cursor: pointer;
position: fixed;
bottom: 2rem;
right: 2rem;
transition: all 0.5s ease -in -out;
z - index: 9999;
@media only screen and(max - width: 650px){
  height: fit - content;
  bottom: 2rem;
  right: 1.5rem;
}

`
