import React from "react";
import Box1 from "../../components/Box1/Box1";
import Box2 from "../../components/Box2/Box2";

const DumpPage = (props) => {

  return (
    <>
      <h1>Dump page</h1>
      <a href="/home">Click to go to home</a>
      <Box1 />
      <Box2 />
      <Box2 />
    </>
  )
}

export default DumpPage;