import React from "react";
import ReactPlayer from "react-player";
import styled from "styled-components";

const VideoPlayerContainer = styled.div`
  padding: 0.3rem 0;
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;

  @media only screen and (max-width: 1440px) {
    display: none;
  }
`;

const VideoPlayer = (props) => {
  return (
    <VideoPlayerContainer>
      <ReactPlayer url={props.src} loop muted playing autoplay />
    </VideoPlayerContainer>
  );
};

export default VideoPlayer;
